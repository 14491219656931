import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Rate from 'rc-rate';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import firebase from '../../../config/firebase';

library.add(faArrowLeft);
var storageRef = firebase.storage().ref();
var imagesRef = storageRef.child('images');
const styles = theme => ({
    root: {
        flexGrow: 1,
        fontSize: '1rem',
        padding: '0 2%',
        fontFamily: 'Teko,  sans-serif'
    },
    laptopGridHeader: {
        textAlign: 'center',
        color: 'black',
        position: 'relative',
    },

    '@keyframes fadein': {
        from: {
            opacity: 0
        },
        to: {
            opacity: 1,
        }
    },
    img: {
        animation: 'fadein 0.75s',
        backgroundColor: 'red',
        width: '100%',
        height: 'auto',
    },
    header: {
        fontSize: '2.5rem',
    },
    backBtn: {
        position: 'absolute',
        left: '0',
        fontSize: '1.1rem',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    info: {
        '& h4': {
            margin: '0.5rem 0',
            '& span': {
                fontSize: '1.5rem',
            }
        },
        '& .brand-header span': {
            color: 'rgb(150, 150, 150)',
        },
        '& .price-header span': {
            color: 'rgb(177, 178, 82)',
        },
        '& small': {
            display: 'block'
        },
        '& .where-find': {
            
        }  
    },
    whereToFind: {
        fontSize: '1.8rem',
        fontWeight: '200',
        fontStyle: 'italic',
        backgroundColor: 'rgb(221, 221, 221)',
        '& .list': {
            fontStyle: 'normal'
        },
        '& header': {
            textDecoration: 'underline'
        }
    },
    rating: {
        marginTop: 'auto'
    }
 
});

class LaptopGrid extends React.Component {
    state = {
        url: '',
        fav: false,
        rated: false,
    }

    componentDidMount() {
        imagesRef.child(this.props.laptop.id.toString() + '.jpg').getDownloadURL().then(url => {
            this.setState({url});
    });}


    render () {
    const { laptop, classes, onClick} = this.props;
    const {url} = this.state;

    return (
        <div className={classes.root}>
            <Grid container spacing={24}>
                <Grid item xs={12}className={classes.laptopGridHeader}>
                    <Button onClick={onClick} className={classes.backBtn} variant="contained">
                        <FontAwesomeIcon icon="arrow-left" /> 
                        Back
                    </Button>
                    <header className={classes.header}>{laptop.name}</header>
                </Grid>
                <Grid item xs={7} sm={5}>
                    <img className={classes.img} src={url} alt={'picture of ' + laptop.name + ' laptop'} />
                </Grid>
                <Grid item xs={5} sm={7} className={classes.info}>
                    <section>
                        <h4 className="brand-header">Brand: <span>{laptop.brand}</span></h4>
                        <h4 className="price-header">Price: <span>${laptop.price}</span></h4>
                        <h4 className={classes.info.rating}>Avg rating: <Rate disabled={true} defaultValue={laptop.avgrating} /></h4>
                        <h4 >Ratings: {laptop.ratings}</h4>
                        <h4 >Favorites: {laptop.favorites > 0 ? laptop.favorites: 0} </h4>
                        

                        {console.log(laptop)}
                        <hr />
                        <summary>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos, praesentium mollitia molestiae illo neque in nobis nam explicabo ducimus. 
                            Nihil voluptatem fuga animi ratione repellat suscipit et molestias, at vitae. Excepteur elit occaecat officia anim cillum culpa duis commodo. 
                            Laboris ea deserunt tempor in ipsum excepteur reprehenderit eu. Veniam ad est anim nostrud do incididunt proident id ea nisi. Reprehenderit nostrud incididunt
                            qui velit in ex aliquip consequat qui qui enim. In ut amet ex labore ea do sit.
                        </summary>
                        <hr/>
                        <small>Operating Sysyem: Windows</small>
                        
                        <small>Resolution: {laptop.resolution} </small>
                        <small>CPU: {laptop.cpu} </small>
                        <small>OS: {laptop.os} </small>
                        <small>Graphics: {laptop.graphics} </small>
                        <small>RAM: {laptop.ram[0]} </small>
                        <small>Storage: {laptop.storage[0]}</small>

                    </section>                    
                </Grid>
                <Grid item xs={12} className={classes.whereToFind}>
                    <header >Where to find?</header>
                    <ul className="list">
                        <li><a href={"https://www.amazon.com/s?k=" + laptop.brand + "+" + laptop.name} rel="noopener noreferrer" target="_blank">AMAZON</a></li>
                        <li><a href="https://www.ebay.com" target="_blank" rel="noopener noreferrer">eBay</a></li>
                        <li><a href="https://www.bestbuy.com" target="_blank" rel="noopener noreferrer">BestBuy</a></li>
                    </ul>
                </Grid>
            </Grid>
        </div>
    );
    }
}

LaptopGrid.propTypes = {
    classes: PropTypes.object.isRequired,
};

LaptopGrid.defaultProps = {
    laptop: {
        os: "Windows",
        ram: '8GB',
        storage: '1000',
    }
}

export default withStyles(styles)(LaptopGrid);