import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

const styles = {
    root: {
        flexGrow: 1
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    }
};

class Account extends React.Component {
    state = {
        anchorEl: null
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    render() {
        const {classes, accountClicked} = this.props;
        const {anchorEl} = this.state;
        const open = Boolean(anchorEl);
        return (
            <div className={classes.root}>
                <div>
                    <IconButton
                        aria-owns={open
                        ? 'menu-appbar'
                        : undefined}
                        aria-haspopup="true"
                        onClick={this.handleMenu}
                        color="inherit">
                        <AccountCircle/>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                        open={open}
                        onClose={this.handleClose}>
                        <MenuItem onClick={this.props.onAccountExitClick}>{accountClicked? 'Exit Profile' : 'Profile'}</MenuItem>
                        <MenuItem onClick={this.props.logout}>Log Out</MenuItem>
                    </Menu>
                </div>
            </div>
        );
    }
}

Account.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Account);