import React from "react";
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import 'rc-rate/assets/index.css';
import LaptopCard from '../LaptopCard/LaptopCard';
import LaptopGrid from './LaptopGrid/LaptopGrid.js';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {Range} from 'rc-slider';
import {fade} from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import green from '@material-ui/core/colors/green';
import Profile from '../Account/Profile'
const drawerWidth = 320;
const styles = theme => ({
    root: {
        display: 'flex'
    },
    grow: {
        flexGrow: 1
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    toolbar: theme.mixins.toolbar,
    
    filters: {
        marginTop: 10,
        width: 300
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing.unit * 2,
        marginLeft: 100,
        width: '100%',
        [
            theme
                .breakpoints
                .up('sm')
        ]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto'
        }
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {width: 200},
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        
        marginLeft: drawerWidth,
    },
    hide: {
        display: 'none',
    },
    root2: {
        //color: green[600],
        '&$checked': {
          color: green[500],
        },
      },
      checked: {},
});

class DataGrid extends React.Component {
    state = {
        expanded: false,
        laptops: [],
        targetlaptop: null,
        laptopCardClicked: false,
        priceValues: [0, 5000],
        ratingValues: [0,5],
        storageValues: [0, 2000],
        sizeValues: [0,7],
        windowsCheck: true,
        macCheck: true,
        linuxCheck: true,
        chromeCheck: true,
        touch: "all",
    };

    componentDidMount() {
        const wordRef = this.props.firebase.database().ref('laptops');
        wordRef.on('value', (snapshot) => {
            let laptops = snapshot.val();
            let newState = [];
            let strArr1 = "";
            let strArr2 = "";
            for (let laptop in laptops) {
                strArr1 = laptops[laptop].ram.split(',');
                let intArr1 = [];
                for(let i=0; i < strArr1.length; i++)
                    intArr1.push(parseInt(strArr1[i]));

                strArr2 = laptops[laptop].storage.split(',');
                let intArr2 = [];
                for(let i=0; i < strArr2.length; i++)
                    intArr2.push(parseInt(strArr2[i]));
                
                newState.push({
                    brand: laptops[laptop].brand,
                    cpu: laptops[laptop].cpu,
                    favorites: laptops[laptop].favorites.count,
                    graphics: laptops[laptop].graphics,
                    id: laptop,                    
                    name: laptops[laptop].name,
                    os: laptops[laptop].os,
                    price: laptops[laptop].price,
                    avgrating: laptops[laptop].rating.average,
                    ram: intArr1,
                    ratings: laptops[laptop].rating.count,
                    resolution: laptops[laptop].resolution,
                    size: laptops[laptop].size,
                    storage: intArr2,
                    touch: laptops[laptop].touch,
                });
            }
            this.setState({laptops: newState});
            //console.log(newState);
        });
    };

    onLaptopGridExitClick = () => {
        this.setState(prevState => ({
            laptopCardClicked: !prevState.laptopCardClicked
        }));
    }

    

    handleChange = priceValues => {
        this.setState({priceValues});
    };

    handleChangeStorage = storageValues => {
        this.setState({storageValues});
    };

    handleChangeSize = sizeValues => {
        this.setState({sizeValues});
    };
    handleInRange = storageVals => {
        for (let i = 0; i < storageVals.length; i++) {
            if(this.state.storageValues[1] >= storageVals[i] && this.state.storageValues[0] <= storageVals[i])   
                return true;
        }
        return false;
    };

    handleChangeRating = ratingValues => {
        this.setState({ratingValues});
    };

    handleChangeOs = name => event =>{
        this.setState({ [name]: event.target.checked });
    };

    handleChangeTouch = event =>{
        this.setState({touch: event.target.value });
    };

    onClick = (laptop) => () => {
        this.onLaptopGridExitClick();
        this.setState({targetlaptop: laptop});
    }

    render() {
        const {classes, open, accountClicked, onAccountExitClick, user} = this.props;
        const {sizeValues, touch, priceValues, storageValues, laptopCardClicked, ratingValues, windowsCheck, macCheck, linuxCheck, chromeCheck, laptops } = this.state;
        const filteredLaptops = this.state.laptops.filter((laptop) => {
                return ( 
                    laptop.name.toLowerCase().indexOf(this.props.search.toLowerCase()) !== -1
                    && ratingValues[1] >= laptop.avgrating
                    && ratingValues[0] <= laptop.avgrating
                    && priceValues[1] >= laptop.price 
                    && priceValues[0] <= laptop.price
                    && (( windowsCheck && laptop.os === "Windows")
                        || (macCheck && laptop.os === "Mac")
                        || (linuxCheck && laptop.os === "Linux")
                        || (chromeCheck && laptop.os === "Chrome OS")) 
                    && (this.handleInRange(laptop.storage))  
                    && (touch === "all" || (touch === laptop.touch))   
                    && ((sizeValues[1] + 10) >= parseFloat(laptop.size))  
                    && ((sizeValues[0] + 10) <= parseFloat(laptop.size))  
                )
        });
            
        let content;

        if (laptopCardClicked) {
            content = (<LaptopGrid laptop={this.state.targetlaptop} onClick={this.onLaptopGridExitClick}/>);
        } else if (accountClicked && user) { 
            content = (<Profile user={user} laptops={laptops} firebase={this.props.firebase} onClick={onAccountExitClick}></Profile>);
        } else {
            content = (
                <div className={classes.root}>
                    <Drawer  open={open} aria-label="Open drawer"   variant="persistent" anchor="left" classes={{ paper: classes.drawerPaper }}>
                        <div className={classes.toolbar}/>
                        <List className={classes.filters} >
                            <ListItem >
                                <Typography variant="button" gutterBottom>
                                    Filter Section
                                </Typography>
                            </ListItem>
                            <ListItem >
                                <div className={classes.inputRoot}>
                                    <Typography variant="body2" gutterBottom>
                                        Price: ${priceValues[0]} : ${priceValues[1]} {priceValues[1] === 5000 ? "+" : null}
                                    </Typography>
                                    <Range min={0} max={5000} step={50} allowCross={false} onChange={this.handleChange} defaultValue={priceValues} pushable={true}/>
                                </div>
                            </ListItem>
                            <ListItem >
                                <div className={classes.inputRoot}>
                                    <Typography variant="body2" gutterBottom>
                                        Rating: {ratingValues[0]} : {ratingValues[1]}
                                    </Typography>
                                    <Range min={0} max={5} step={1} allowCross={false} onChange={this.handleChangeRating} defaultValue={ratingValues}/>
                                </div>
                            </ListItem>
                            <ListItem >
                                <div className={classes.inputRoot}>
                                <Typography variant="body2" gutterBottom>
                                        Operating System:
                                    </Typography>
                                <FormControlLabel control={
                                    <Checkbox
                                    checked={windowsCheck}
                                    onChange={this.handleChangeOs("windowsCheck")}
                                    value="windowsCheck"
                                    color="primary"
                                    />
                                }
                                label="Windows"
                                />
                                <FormControlLabel control={
                                    <Checkbox
                                    checked={macCheck}
                                    onChange={this.handleChangeOs("macCheck")}
                                    value="macCheck"
                                    color="primary"
                                    />
                                }
                                label="MacOS"
                                />
                                 <FormControlLabel control={
                                    <Checkbox
                                    checked={chromeCheck}
                                    onChange={this.handleChangeOs("chromeCheck")}
                                    value="chromeCheck"
                                    color="primary"
                                    />
                                }
                                label="ChromeOS"
                                />
                                 <FormControlLabel control={
                                    <Checkbox
                                    checked={linuxCheck}
                                    onChange={this.handleChangeOs("linuxCheck")}
                                    value="linuxCheck"
                                    color="primary"
                                    />
                                }
                                label="Linux"
                                />
                                </div>
                            </ListItem>
                            <ListItem >
                                <div className={classes.inputRoot}>
                                    <Typography variant="body2" gutterBottom>
                                        RAM GB:
                                    </Typography>
                                    <Range min={0} max={10} step={null} marks={{ 0:0, 1:1, 2:2, 3:4, 4:6, 5:8, 6:12, 7:16, 8:32, 9:64, 10:128 }} defaultValue={[0, 10]} allowCross={false} />
                                </div>
                            </ListItem>
                            <ListItem >
                                <div className={classes.inputRoot}>
                                    <Typography variant="body2" gutterBottom>
                                        Storage: {storageValues[0]} : {storageValues[1]}
                                    </Typography>
                                    <Range min={0} max={2000} step={10} onChange={this.handleChangeStorage} defaultValue={storageValues} allowCross={false} />
                                </div>
                            </ListItem>
                            <ListItem  >
                            <div>
                            <Typography variant="body2" gutterBottom>
                                        Touch:
                            </Typography>
                            <FormControlLabel control={
                                     <Radio
                                        checked={this.state.touch === 'all'}
                                        onChange={this.handleChangeTouch}
                                        value="all"
                                        name="radio-button-all"
                                        aria-label="All"
                                        classes={{
                                            root: classes.root2,
                                            checked: classes.checked,
                                          }}
                                     />
                                }
                                label="All"
                                />
                           <FormControlLabel control={
                                <Radio
                                checked={this.state.touch === 'yes'}
                                onChange={this.handleChangeTouch}
                                value="yes"
                                name="radio-button-yes"
                                aria-label="Yes"
                                classes={{
                                    root: classes.root2,
                                    checked: classes.checked,
                                  }}
                                />
                                }
                                label="Yes"
                            />
                            <FormControlLabel control={
                                <Radio
                                checked={this.state.touch === 'no'}
                                onChange={this.handleChangeTouch}
                                value="no"
                                name="radio-button-no"
                                aria-label="No"
                                classes={{
                                    root: classes.root2,
                                    checked: classes.checked,
                                  }}
                                />
                                }
                                label="No"
                            />
                            </div>
                            </ListItem>
                            <ListItem>
                            <div className={classes.inputRoot}>
                                    <Typography variant="body2" gutterBottom>
                                        Laptop Screen Size:
                                    </Typography>
                                    <Range min={0} max={7} step={null} onChange={this.handleChangeSize} marks={{ 0:10, 1:11, 2:12, 3:13, 4:14, 5:15, 6:16, 7:17}} defaultValue={sizeValues} allowCross={false} />
                            </div>
                            </ListItem>
                        </List>

                    </Drawer>
                    <main className={classNames(classes.content, {[classes.contentShift]: open, })}>
                        <Grid container className={classes.dgrid} spacing={24}>
                            <Grid item xs>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                    {filteredLaptops.map((laptop) => {
                                        //console.log(laptop);
                                        return <LaptopCard key={laptop.id} laptop={laptop} user={this.props.user} onClick={this.onClick(laptop)}/>
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </main>
                </div>
            );
        }

        return (
            <div>
                <div className={classes.toolbar}/> {content}
            </div>
        );
    }
}
DataGrid.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(DataGrid);
