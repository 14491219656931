import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import classNames from 'classnames';

import A0001 from './Articles/a0001';
const drawerWidth = 320;
const styles = theme => ({
    root: {
        display: 'flex'
    },
    proot: {
        padding: theme.spacing.unit * 2,
    },
    grow: {
        flexGrow: 1
    },
    toolbar: theme.mixins.toolbar,
    filters: {
        marginTop: 10,
        width: 300
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        
        marginLeft: drawerWidth,
    },
    avatar: {
        margin: 10,
        width: 50,
        height: 50,
    },
    rightmostIcon: {
        marginRight: 5,
    },
});

class Blog extends React.Component {
    render() {
        const {classes, open, windowLength} = this.props;
        let lenvalue;
        if(windowLength > 1250) {
            lenvalue = 6;
        } else if ( windowLength > 700){
            lenvalue = 8;
        } else {
            lenvalue = 10;
        }
        return(
            <div>
                <div className={classes.toolbar}/>
                <div className={classes.root}>
                    <Drawer  open={open} aria-label="Open drawer"   variant="persistent" anchor="left" classes={{ paper: classes.drawerPaper }}>
                        <div className={classes.toolbar}/>
                        <List className={classes.filters} >
                            <ListItem >
                                <Typography variant="button" gutterBottom>
                                    Section
                                </Typography>
                            </ListItem>
                        </List>
                    </Drawer>
                    <main className={classNames(classes.content, {[classes.contentShift]: open, })}>
                        <A0001 lenvalue={lenvalue}/>
                    </main>
                </div>
            </div>
        )
    }
}

Blog.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Blog);