import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Dialog from '../Dialog/Dialog';
import { FormControl } from "@material-ui/core";


class SignOn extends React.Component {
    state = {
      open: false,
			openSignUp: false,
			email: "",
			emailSignUp: "",
			password: "",
			passwordSignUp: "",
			retypeSignUpPassword: "",
      showPassword: false,
    };


  login = (e) =>{
		e.preventDefault();
    this.props.firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then((u)=>{
    }).catch((error) => {
        console.log(error);
      });
	};


	signUp = (e) =>{
		this.props.success(); // this gets called before account creation otherwise never hits
		e.preventDefault();
		this.props.firebase.auth().createUserWithEmailAndPassword(this.state.emailSignUp, this.state.passwordSignUp).catch( (error) => {
		}).catch((error) => {
			console.log(error);
		});
	}

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };
  
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClickOpenSignUp = () => {
    this.setState({ openSignUp: true });
  };

  handleClose = () => {	
    this.setState({ open: false });
  };

  handleSignUpClose = () => {
  	this.setState({ openSignUp: false });
  }


 
	
  validateForm = () => {

    return this.state.email.length > 0 && this.state.password.length > 0;
  };

  validateSignUpForm = () => {
		return this.state.emailSignUp.length > 0 && this.state.passwordSignUp === this.state.retypeSignUpPassword && this.state.passwordSignUp.length === this.state.retypeSignUpPassword.length;
  }

  render() {
	
	let formControlContent;
	const { 
		open, 
		openSignUp, 
		email, 
		emailSignUp, 
		retypeSignUpPassword, 
		passwordSignUp 
	} = this.state;

    if(open){

		formControlContent = 
		(<React.Fragment>
            <InputLabel htmlFor="adornment-password">Password</InputLabel>
            <Input id="adornment-password"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password} onChange={this.handleChange('password')}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword} >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>}
						/>
		</React.Fragment>)
		    
    }else {
		
		formControlContent =
		(<React.Fragment>
			<FormControl>
				<InputLabel htmlFor="sign-up-password">Password</InputLabel>
				<Input id="sign-up-password"
					type={this.state.showPassword ? "text" : "password"}
					value={passwordSignUp} onChange={this.handleChange('passwordSignUp')}
					endAdornment={
						<InputAdornment position="end">
							<IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword} >
								{this.state.showPassword ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</InputAdornment>}
				/>
			</FormControl>
			<FormControl>
				<InputLabel htmlFor="retype-password">Retype Password</InputLabel>
				<Input id="retype-password"
					type={this.state.showPassword ? "text" : "password"}
					value={retypeSignUpPassword} onChange={this.handleChange('retypeSignUpPassword')}
					endAdornment={
						<InputAdornment position="end">
							<IconButton aria-label="Toggle password visibility" onClick={this.handleClickShowPassword} >
								{this.state.showPassword ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</InputAdornment>}
				/>
			</FormControl>
		</React.Fragment>)
    }
    
    
    return (
      <div>
        <Button color="inherit" onClick={this.handleClickOpen}>
          Login
        </Button>
        <Button color="inherit" onClick={this.handleClickOpenSignUp}>
          Sign Up
        </Button>
			<Dialog
				title="Login"
				open={open}
				onClose={this.handleClose}
				onEmailChange={this.handleChange("email")}
				onEmailValue={email}
				formInputs={formControlContent}
				handleClose={this.handleClose}
				handleFormClick={this.login}
				formBtnDisabled={!this.validateForm()}>
			</Dialog>
			<Dialog
				title="Sign Up"
				open={openSignUp}
				onClose={this.handleSignUpClose}
				onEmailChange={this.handleChange("emailSignUp")}
				onEmailValue={emailSignUp}
				formInputs={formControlContent}
				handleClose={this.handleSignUpClose}
				handleFormClick={this.signUp}
				formBtnDisabled={!this.validateSignUpForm()}>
			</Dialog>
        
      </div>
    );
  }
}

export default SignOn