import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LaptopCard from '../LaptopCard/LaptopCard';
import LaptopGrid from '../DataGrid/LaptopGrid/LaptopGrid';
//import { userInfo } from 'os';
const styles  = ({
    root: {
        flexGrow: 1
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    paper: {
        padding: '20px',
        margin: '10 2px',
        textAlign: 'center',
    
    }
});

class Profile extends React.Component {
    state = {
        display : 0,
        laptops: [],
        favoritesExist: false,
        targetlaptop: null,
    };

    onLaptopGridExitClick = () => {
       if(this.state.display !== 1)
       {
            this.setState({display: 1})
       } else {
            this.setState({display: 0})
       }
    }

    onClick = (laptop) => () => {
        this.onLaptopGridExitClick();
        this.setState({targetlaptop: laptop});
    }
    updateFavorites = () => {
        if(this.props.user){

            let userID = this.props.firebase.auth().currentUser.uid;

            const wordRef = this.props.firebase.database().ref('users/' + userID);
            wordRef.once('value', (snapshot) => {
                if (snapshot.hasChild('favoritesList')) {
                    //   console.log('exists');
                    //   console.log(snapshot.val().favoritesList);
                  let newState = [];
                  this.setState({favoritesExist: true});
                  let list = snapshot.val().favoritesList;   
                  for(let item in list)
                  {
                    console.log(this.props.laptops[this.props.laptops.findIndex(x => x.id === item)]);
                    newState.push(this.props.laptops[this.props.laptops.findIndex(x => x.id === item)]);
                  }
                  this.setState({laptops: newState})               
                }
                else{
                    // console.log('nope');
                    this.setState({favoritesExist: true});
                }
            });
           
            
        }
    }

    componentDidMount() {
        this.updateFavorites();
    };

    render() {
        const {classes} = this.props;
        const {display, laptops} = this.state;
        let content;
        if(display === 0){
            content = ( 
                <div>
                    <Grid container
                            direction="column"
                            justify="flex-start"
                            alignItems="center"
                        >
                        <Grid item>

                                <Typography variant="h5" gutterBottom>
                                    Favorited Laptops
                                    
                                </Typography>
      
                        </Grid>
                        <Grid item>
                                
                                    {laptops.map((laptop) => {
                                        //console.log(laptop);
                                        return <LaptopCard key={laptop.id} laptop={laptop} user={this.props.user} onClick={this.onClick(laptop)}/>
                                    })}
                                
                            </Grid>
                    </Grid>
                </div>)
        } else if (display === 1){
            content = (<LaptopGrid laptop={this.state.targetlaptop} onClick={this.onLaptopGridExitClick}/>);
        } else{
            content = (<div>Profile 0 not active</div>)
        }


        return (
            <div className={classes.root}>
              {content}
            </div>
        );
    }
}

Profile.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Profile);