import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import DataGrid from './DataGrid/DataGrid';
import firebase from '../config/firebase';
import SignOn from './SignOn/SignOn';
import Account from './Account/Account';
import Dialog from '@material-ui/core/Dialog';
import Button from "@material-ui/core/Button";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import 'rc-slider/assets/index.css';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import {fade} from '@material-ui/core/styles/colorManipulator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Blog from './Blog/Blog';

const drawerWidth = 320;
const styles = theme => ({
    root: {
        display: 'flex'
    },
    grow: {
        flexGrow: 1
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1
    },
    button: {
        marginLeft: theme.spacing.unit + 1,
        fontSize: '1.1rem',
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3
    },
    dgrid: {
        textAlign: "center"
    },
    filters: {
        marginTop: 10,
        width: 300
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing.unit * 2,
        
        width: '100%',
        [
            theme
                .breakpoints
                .up('sm')
        ]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto'
        }
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    icon: {
        marginRight: theme.spacing.unit,
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme
            .transitions
            .create('width'),
        width: '100%',
        [
            theme
                .breakpoints
                .up('md')
        ]: {
            width: 200
        }
    }
});

class MainBody extends React.Component {
    state = {
        user: null,
        search: "",
        openAccountCreated: false,
        open: false,
        accountClicked: false,
        anchorEl: null,
        blog: true,
        windowLength: window.innerWidth,
    };

    componentDidMount() {
        this.authListener();
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    };

    updateDimensions() {
        this.setState({windowLength: window.innerWidth});
    };

    resize() {
        if(this.state.open){
            this.setState({open: window.innerWidth >= 650});
        }
        this.setState({windowLength: window.innerWidth});   
    };

    authListener = () => {
        firebase
            .auth()
            .onAuthStateChanged((user) => {
                if (user) {
                    this.setState({user});
                    localStorage.setItem('user', user.uid);
                } else {
                    this.setState({user: null});
                    localStorage.removeItem('user');
                }
            })
    };


    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };
    
    handleDrawerToggle = () => {
        this.setState({ open: !this.state.open });
    };

    searchEventHandler = (event) => {
        this.setState({search: event.target.value})
    };

    handleCreatedOpen = () => {
        this.setState({ openAccountCreated: true});
    };
        
    handleCreatedClose = () => {
        this.setState({ openAccountCreated: false });
    };

    handleBlogOpen = () => {
        console.log("blog triggered");
        this.setState({blog: true});
        this.setState({open: false});
  
    };

    handleBlogClose = () => {
        this.setState({blog: false});
        if(!this.state.open){
            this.setState({open: window.innerWidth >= 650});
        }   
    };

    logout = () => {
        firebase.auth().signOut().then(function () {
                // Sign-out successful. window.alert("You are logged out now!");
            })
            .catch(function (error) {
                // An error happened.
            });
            this.handleClose(); 
    };

    onAccountExitClick = () => {
        this.setState(prevState => ({
            accountClicked: !prevState.accountClicked
        })); 
    };

    render() {
        const {classes} = this.props;
        const {user, windowLength, search, openAccountCreated, open, accountClicked, anchorEl, blog} = this.state;
        const dotexpand = Boolean(anchorEl);
        let content;
        if (blog) {
            content = 
            (<Blog 
                windowLength={windowLength}
                search={search}
                open={open}
                firebase={firebase}
                user={user}
                />);
        } else {
            content = 
            (   <DataGrid
                onAccountExitClick={this.onAccountExitClick}
                accountClicked={accountClicked}
                search={search}
                open={open}
                firebase={firebase}
                user={user}/>);
        }
        return (
            <div>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar variant="dense">
                        <IconButton className={classes.menuButton} onClick={this.handleDrawerToggle} color="inherit" aria-label="Menu">
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.title}>
                            {windowLength >= 800 ? 'laptop llama': 'lllama'}
                        </Typography>
                        

                        
                        {blog ? 
                                <Button onClick={this.handleBlogClose} color="inherit" className={classes.button}>
                                    <FontAwesomeIcon icon={faNewspaper} className={classes.icon} /> 
                                    {windowLength >= 800 ? 'index': ''}
                                </Button>
                    
                            :
                                <Button onClick={this.handleBlogOpen} color="inherit" className={classes.button}>
                                    <FontAwesomeIcon icon={faNewspaper} className={classes.icon} /> 
                                    {windowLength >= 800 ? 'blog': ''}
                                </Button>
                            
                        }
                        
                        
                        <div className={classes.grow}/>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon/>
                            </div>
                            <InputBase
                                placeholder="Search…"
                                onChange={this.searchEventHandler}
                                value={search}
                                classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput}}
                                autoFocus
                            />
                        </div>
                        <div className={classes.grow}/>
                        
                            <div>
                                {user ? 
                                (<Account logout={this.logout} onAccountExitClick={this.onAccountExitClick} user={user} accountClicked={accountClicked}/>)
                                : <div>
                                        {windowLength >= 800 ? 
                                            (<SignOn success={this.handleCreatedOpen} firebase={firebase}/>)                          
                                        :
                                            <div>
                                                <IconButton
                                                aria-label="more"
                                                aria-controls="long-menu"
                                                aria-haspopup="true"
                                                color="inherit"
                                                onClick={this.handleMenu}>
                                                <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id="menu-appbar"
                                                        anchorEl={anchorEl}
                                                        anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right'
                                                    }}
                                                        transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right'
                                                    }}
                                                        open={dotexpand}
                                                        onClose={this.handleClose}>
                                                    <SignOn success={this.handleCreatedOpen} firebase={firebase}/>       
                                                </Menu>
                                            </div>}
                                    </div>
                            }
                            </div>
                    </Toolbar>
                </AppBar>
                                <Dialog
                                    open={openAccountCreated}
                                    onClose={this.handleCreatedClose}
                                >
                                   <DialogContent>
                                   <Typography>
                                        Welcome to Laptop llama! <br />
                                        Your Account has been created!
                                    </Typography>
                                   </DialogContent>
                                   <DialogActions>
                                        <Button onClick={this.handleCreatedClose} color="primary">
                                           Ok.
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                <div>
                    {content}
                </div>
            </div>
        );
    }
}

MainBody.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MainBody);