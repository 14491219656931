import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const config = {
  apiKey: "AIzaSyDaBnHKXWx84IzxKPZdeVlYVY_k8kt7UzQ",
  authDomain: "lllama-99c0c.firebaseapp.com",
  databaseURL: "https://lllama-99c0c.firebaseio.com",
  storageBucket: "lllama-99c0c.appspot.com",
  projectId: "lllama-99c0c",
};

// require('firebase/auth');
firebase.initializeApp(config);
export default firebase;