import React from 'react';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";

// Dialog Box used by the Login and SignUp Components
const DialogBox = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email Address"
                    type="email"
                    onChange={props.onEmailChange}
                    value={props.emailValue}
                    fullWidth/>
                <br/>
                <br/>
                <FormControl>
                    {props.formInputs}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={props.handleFormClick}
                    disabled={props.formBtnDisabled}
                    color="primary">
                    {props.title}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogBox;
