import React from 'react';
import Rate from 'rc-rate';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import firebase from '../../config/firebase';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import green from '@material-ui/core/colors/green';
import Checkbox from '@material-ui/core/Checkbox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
// Points to the root reference
var storageRef = firebase.storage().ref();
// Points to 'images'
var imagesRef = storageRef.child('images');
// Points to 'images/space.jpg'
// Note that you can use variables to create child values
//var fileName = '1.png';
//var spaceRef = imagesRef.child(fileName).getDownloadURL();
// File path is 'images/space.jpg'
//var path = spaceRef.fullPath;
//console.log(path);
//console.log(spaceRef);



function updateRateDB(id) {
    let tRatings = [];
    const wordRef = firebase.database().ref('laptops/' + id + '/ratingsList');
    wordRef.on('value', (snapshot) => {
        let rating = snapshot.val();
        for (let score in rating) {
            tRatings.push({value: rating[score].rating});
        }
    });

    let tcount = tRatings.length;
    let tsum = 0;
    for (let i = 0; i < tcount; i++) {
        tsum += Number(tRatings[i].value);
        //console.log('id: ' + i + ' ' + tRatings[i].value);
    }
    let taverage = tsum / tcount;
    firebase.database().ref('laptops/' + id + '/rating').set({average: taverage, count: tcount});
}

const styles = theme => ({
    card: {
        width: 280,
        height: 345,
        margin: 12,
        backgroundColor: '#f5f5f5'
    },
    media: {
        title: {
            variant: "h6"
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundImage: 'linear',
            opacity: '0.80',
        },
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    cardnav: {
        marginLeft: 12
    },
    pricetag: {
        marginRight: 24
    },
    avatar: {
        textAlign: 'left',
        backgroundColor: green[500],
      },
    greenRate: {
        color: green,
    },
    buttonTF: {
        marginTop: 16,
        marginRight: 10,
    }
});

class LaptopCard extends React.Component {
    state = {
        url: '',
        fav: false,
        rated: false,
        open: false,
    }
    updateRating = (id, rate) => {
        let userID = firebase.auth().currentUser.uid;
        //console.log('laptop id ', id, ' rating ', rate);
        firebase.database().ref('laptops/' + id + '/ratingsList/' + userID).set({
                rating: rate
            }, function (error) {
                if (error) {
                    // The write failed...
                } else {
                    updateRateDB(id); // If data was added to the /ratingList/ then we will update the rating average and count
                }
            });
        firebase.database().ref('users/' + userID + '/ratings/' + id).set({rating: rate});
    }

    updateFavorite = () => {
        ////////////////////
        ///NEW
         //need to see if user has any items favorited
         if(this.props.user != null) {
            const wordsRef = firebase.database().ref('laptops/' +  this.props.laptop.id );
            wordsRef.on('value', (snapshot) => {
                if(snapshot.hasChild( 'favoritesList' )){
                    let val = {key: undefined}
                    val = snapshot.val().favoritesList[this.props.user.uid];  
                    //console.log(val);
                    //console.log(list[this.props.user.uid]);

                    if (val !== undefined) {
                     //console.log("Laptop: ", this.props.laptop.id, " Is favorited by ", this.props.user.uid);
                     this.setState({fav: true});
                   }
                    // if(users[this.props.user.uid] != null)
                    //     console.log("It is favorited!!");
                }
            }); 
        }
        else{
            this.setState({fav: false});
         }
    }

    authListener = () => {
        this.fireBaseListener = firebase.auth().onAuthStateChanged((user) => {
                this.updateFavorite();
        });
    }

    componentDidMount() {
        imagesRef.child(this.props.laptop.id.toString() + '.jpg').getDownloadURL().then(url => {
            this.setState({url});
        });
        this.authListener();
        this.updateFavorite();
    }
    handleClickOpen = () => {
        this.setState({open: true});
    }
    
    handleClose = () =>{
        this.setState({open: false});
    }

    onClickFavorite = () => {
        
        if(this.props.user != null) {
            if(this.state.fav){ // remove from favorites
                let count = 0;
                firebase.database().ref('laptops/' + this.props.laptop.id).child("favoritesList").on("value", function(snapshot) {
                    count = snapshot.numChildren()
                });
                count -= 1;
                firebase.database().ref('laptops/' + this.props.laptop.id + "/favorites").set({
                    count: count
                });
                firebase.database().ref('users/' + this.props.user.uid + '/favoritesList/').child(this.props.laptop.id).remove();
                firebase.database().ref('laptops/' + this.props.laptop.id +'/favoritesList/').child(this.props.user.uid).remove();
                
                this.setState({fav: false});
            }else{ //add to favorites
                let count = 0;
                firebase.database().ref('laptops/' + this.props.laptop.id +'/favoritesList/' + this.props.user.uid).set({
                    favorite: true
                });
                firebase.database().ref('users/' + this.props.user.uid + '/favoritesList/' + this.props.laptop.id).set({
                    favorite: true
                });
                firebase.database().ref('laptops/' + this.props.laptop.id).child("favoritesList").on("value", function(snapshot) {
                    count = snapshot.numChildren()
                });
                firebase.database().ref('laptops/' + this.props.laptop.id + "/favorites").set({
                    count: count
                });
                this.setState({fav: true});
            }
        }
    }

    render() {
        const { classes, onClick } = this.props;
        const {open, url, fav, rated} = this.state;
        let ratedColor = rated ? "greenRate" : "default";
        return (

            <div>
                <Card className={classes.card} >
                    <CardHeader avatar={
                        <Avatar aria-label="Recipe" className={classes.avatar}>
                            {this.props.laptop.brand[0]}
                        </Avatar>}
                            title={this.props.laptop.name}
                            subheader={this.props.laptop.brand}
                            action={
                                <Checkbox 
                                checked={fav}
                                icon={<FavoriteBorder />} 
                                checkedIcon={<Favorite />} 
                                onClick={this.onClickFavorite}
                                value="checkedH" />
                            }/>
                    <CardMedia
                        className={classes.media}
                        image={url}
                        src={this.props.laptop.id + ".jpg"}
                        title={this.props.laptop.name}
                        onClick={onClick}/>
                    <CardContent>
                        <Typography component="p">
                            This is a laptop
                        </Typography>
                    </CardContent>
                    <Grid
                        className={classes.cardnav}
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start">
                        <Rate
                            onChange={this
                            .updateRating
                            .bind(this, this.props.laptop.id)}
                            disabled={this.props.user == null} //was: this.props.user == null // true
                            defaultValue={this.props.laptop.avgrating}
                            style={{
                                fontSize: 23,
                                color: '#34d849',
                            }}
                            allowHalf
                            allowClear={false}/>
                        <Typography variant="overline">{this.props.laptop.ratings
                                ? this.props.laptop.ratings
                                : 0}</Typography>
                        <Button variant="contained" onClick={this.handleClickOpen} className={classes.pricetag} color="secondary">
                            {'$ ' + (this.props.laptop.price).toFixed(2)}
                        </Button>
                    </Grid>
                </Card>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Product Affiliate Links"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    </DialogContentText>
                    <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="stretch"
                    >
                        <Grid item>
                            <Button className={classes.buttonTF} onClick={this.handleClose} color="primary">
                            <a href={"https://www.amazon.com/s?k=" + this.props.laptop.brand + "+" + this.props.laptop.name} rel="noopener noreferrer" target="_blank">AMAZON</a>
                            </Button>
                            <TextField
                                id="standard-read-only-input"
                                defaultValue="Hello World"
                                className={classes.textField}
                                margin="normal"
                                InputProps={{
                                readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button className={classes.buttonTF} onClick={this.handleClose} color="primary">
                                NewEgg
                            </Button>
                            <TextField
                                    id="standard-read-only-input"
                                    defaultValue="Hello World"
                                    className={classes.textField}
                                    margin="normal"
                                    InputProps={{
                                    readOnly: true,
                                    }}
                                />
                        </Grid>
                    </Grid>
                    </DialogContent>
                    <DialogActions>
                    
                    </DialogActions>
                </Dialog>
            
            </div>
        );
    }
}

LaptopCard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LaptopCard);