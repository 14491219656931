import React from 'react';
import ReactDOM from 'react-dom';
import MainBody from './components/MainBody';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
//import Block from './components/Block'
//import Control from './superSecretFolder/Control';
const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
      },
    palette: {
        background: {
            default: '#edeff4'
        },
        primary: {
            main: '#1e88e5'
        },
        secondary: {
            light: '#b0bec5',
            main: '#e53935',
            contrastText: '#fafafa'
        }
    }
});



ReactDOM.render(
    <MuiThemeProvider theme={theme}>
    <CssBaseline/>
    {/* <Control /> */}
    {/* <Block /> */}
    <MainBody />
   
</MuiThemeProvider>, document.getElementById('nodramallama'));