
import React, {useRef, useEffect, Component} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import APavatar from '../andrew.jpg';
import GoogleFontLoader from 'react-google-font-loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebook, faReddit, faLinkedin, faTwitterSquare } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";
import IconButton from '@material-ui/core/IconButton';
import {TwitterShareButton, FacebookShareButton, FacebookShareCount, RedditShareCount, LinkedinShareButton, RedditShareButton,
    EmailShareButton, } from 'react-share';
import TextField from '@material-ui/core/TextField';
import {CopyToClipboard} from 'react-copy-to-clipboard';
const styles = theme => ({
    rightmostIcon: {
        marginRight: 5,
    },
    center: {
        textAlign: 'center'
    },
    icon: {
        width: 40,
        height: 40,
    },
    avatar: {
        margin: 10,
        width: 50,
        height: 50,
    },
    colored: {
        color: "#1e88e5"
    },
    greenCheck: {
        color: "#4bbd62",
        width: 40,
        height: 40,
        marginRight: 10,
    },
    grayedCheck: {
        color: "#cfcfcf",
        width: 40,
        height: 40,
        marginRight: 10,
    }
});

class A0001 extends Component {
    state = {
        shareUrl: "https://laptopllama.com/",
        copied: false,
    };

    render() {
        const {lenvalue, classes} = this.props;
        const {shareUrl, copied} = this.state;
        return(
            <div>
             <GoogleFontLoader
                            fonts={[
                            {
                                font: 'Quattrocento Sans',
                                weights: [400, 800]
                            },
                            {
                                font: 'Quicksand',
                                weights: [500, 800]
                            }                    
                        ]} />
                        <Grid container direction="column" justify="center" alignItems="center" spacing={24}>
                            <Grid item xs={lenvalue}>
                                   <Typography variant="h3" style={{ fontFamily: 'Quicksand'}} gutterBottom>
                                        Information Privacy and Surveillance
                                    </Typography>
                                    <Typography variant="h5" style={{ fontFamily: 'Quicksand'}} gutterBottom>
                                        Living in the digital age
                                    </Typography>
                                    <Grid container direction="row" justify="space-between" alignItems="center">

                                        <Grid item>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" >
                                                <Avatar alt="AP" src={APavatar} className={classes.avatar} />
                                                
                                                    <div>
                                                        <Typography variant="subtitle1"  >
                                                            Andrew Price
                                                        </Typography>
                                                        <Typography variant="caption" display="block" >
                                                            Nov 20, 2019
                                                        </Typography>
                                                    </div>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" justify="flex-end" alignItems="flex-start" spacing={24}>                                            
                                                <div className={classes.center}>
                                                    <IconButton aria-label="facebook" color="primary" className={classes.icon}>
                                                        <FacebookShareButton url={shareUrl}>
                                                            <FontAwesomeIcon icon={faFacebook} />
                                                        </FacebookShareButton>
                                                    </IconButton>
                                                    {/* <FacebookShareCount url={shareUrl}>
                                                        {shareCount => (
                                                            <span className="myShareCountWrapper">{shareCount}</span>
                                                        )}
                                                    </FacebookShareCount> */}
                                                </div>
                                                <div className={classes.center}>
                                                    <IconButton aria-label="reddit" color="secondary" className={classes.icon}>
                                                        <RedditShareButton url={shareUrl}>
                                                            <FontAwesomeIcon icon={faReddit} />
                                                        </RedditShareButton>
                                                    </IconButton>
                                                    {/* <RedditShareCount url={shareUrl}>
                                                        {shareCount => (
                                                            <span className="myShareCountWrapper">{shareCount}</span>
                                                        )}
                                                    </RedditShareCount> */}
                                                </div>
                                                <IconButton aria-label="twitter" color="primary" className={classes.icon}>
                                                    <TwitterShareButton url={shareUrl}>
                                                        <FontAwesomeIcon icon={faTwitter} />
                                                    </TwitterShareButton>
                                                </IconButton>
                                                <IconButton aria-label="linkedin" color="primary" className={classes.icon}>
                                                    <LinkedinShareButton url={shareUrl}>
                                                        <FontAwesomeIcon icon={faLinkedin} />
                                                    </LinkedinShareButton>
                                                </IconButton>
                                                <IconButton aria-label="viber" color="primary" className={classes.icon}>
                                                    <EmailShareButton url={shareUrl}>
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                    </EmailShareButton>
                                                </IconButton>
                                                <CopyToClipboard text={shareUrl}
                                                    onCopy={() => this.setState({copied: true})}>
                                                    <IconButton aria-label="copy" onClick={this.copyToClipboard} color="primary" className={classes.icon}>                                                  
                                                        <FontAwesomeIcon icon={faCopy} />
                                                    </IconButton>
                                                </CopyToClipboard>
                                                <FontAwesomeIcon icon={faCheck} className={copied ? classes.greenCheck : classes.grayedCheck} />
                                            </Grid>
                                        </Grid>
                                    </Grid >
                                    <img src={ require('./privacy.jpg') } style={{maxWidth:"100%", marginTop: 15}} />
                                    <p gutterBottom style={{ fontFamily: 'Quattrocento Sans',fontSize: '19px', lineHeight: '28px',  }} >
                                        People are more connected to information than ever before thanks to the world wide web. 
                                        Where events, beliefs, and facts are just one internet search away. 
                                        However, access to information doesn’t stop there! <b>Any information stored on a device with internet access is vulnerable to being accessed. </b>
                                        Furthermore, not only is data vulnerable to outside eyes, but sensors on your devices are vulnerable to being tapped and monitored. 
                                        <br/><br/>
                                        Whether it’s your smartphone, laptop, or smart home device; devices connected to the world wide web can, have, and will continue to be stripped of privacy and surveyed by others. 
                                        <b>The more online services you use the more vulnerable you become.</b>
                                        Whether you have healthy online hygiene or not, information privacy is becoming more and more a thing of the past. 
                                        There is a very high chance that I a total stranger can find your phone number, date of birth, relatives, email addresses, passwords, and physical home address just by doing a quick search using your name and zip code. 
                                        This is not your fault it just one of the unfortunate consequences of living in the 21st century. 
                                    </p>

                                    <b style={{ fontFamily: 'Quattrocento Sans',fontSize: '30px', lineHeight: '40px',  }}>
                                        As more and more people rely on technology, freedom of privacy diminishes, and governments lack the ability to preserve its citizens freedom of privacy.
                                    </b>

                                    <p style={{ fontFamily: 'Quattrocento Sans',fontSize: '19px', lineHeight: '28px',  }}>
                                        It is very scary to experience your mortality; to recognize that you are completely naked. 
                                        This happened to me in early 2018 when I discovered nothing I have is private. 
                                        I encourage you to discover to what extent your digital privacy has been violated. 
                                        There is a website called “<a href={"https://haveibeenpwned.com/"}  target={"_blank"}>https://haveibeenpwned.com/</a>” that will let you know if your email has ever been compromised. 
                                        And perhaps even scarier there is a website “<a href={"https://www.truepeoplesearch.com/"}  target={"_blank"}>https://www.truepeoplesearch.com/</a>” that may know  more information about you than you are comfortable with. 
                                        I encourage you to view these sites at your own risk and to use them responsibly. 
                                        However this is just the surface. 
                                        Information warfare has been around since the 80s, that is, technology designed to collect, manipulate, and monitor devices are being used as we speak on a national scale. 
                                        I a simple computer science student have the necessary knowledge to take control of your webcam. 
                                        If I am capable of that, imagine what an entire nation can do. 
                                        In the movie <a href={"https://en.wikipedia.org/wiki/Snowden_(film)"}>Snowden</a> you can see just to what gross extent the United States Government has gone in violating its citizens Fourth Amendment rights.
                                    </p>
                                    <img src={ require('./equifax.jpeg') } style={{maxWidth:"100%", marginTop: 15}} />
                                    <p style={{ fontFamily: 'Quattrocento Sans',fontSize: '19px', lineHeight: '28px',  }}>
                                        One example of information privacy being violated is the Equifax hack of 2017 where hackers stole personal information from 145.5 million Americans. 
                                        This breach stands out among previous high-profile breaches for the number of Americans affected as well as the types of data disclosed – Social Security numbers, residential addresses, and credit histories” <a>[1]</a>. 
                                        This means that if you filed taxes in 2016 there is a 50% chance that your information may have been stolen. 
                                        Using stolen information hackers could impersonate you by signing you up for credit cards effectively ruining your credit score. 
                                        I encourage you to monitor your credit history and see if you were affected by this hack, you can do so at this website: “<a href={"https://www.equifaxbreachsettlement.com"}  target={"_blank"}>https://www.equifaxbreachsettlement.com</a>”. 
                                        This is just one small instance of a known data breach. 
                                        You may be horrified to learn of the large volume of data breaches that have occurred on companies from Google to Bank of America, almost every company has or will be hacked. 
                                        In an article written in 2018 Taylor Armerding writes about the 18 largest data breaches where Equifax ranks as only the fifth and Yahoo leads the herd with a whopping 3 billion user accounts hacked [3]. 
                                        Today the Equifax hack has dropped down to the 13th biggest hack and will only continue to move down the list! The fact is that no person or company is completely safe.
                                    </p>
                                    <p style={{ fontFamily: 'Quattrocento Sans',fontSize: '19px', lineHeight: '28px',  }}>
                                        Aside from being hacked your data may be vulnerable simply because you are unknowingly legally giving it away. It is common for users to freely give companies access to information they believe to be private. For example, if you use Google Drive you are giving Google rights to use your data any way they want. One large company was caught in the act: Facebook. How do large companies like Facebook make so much money? One strategy is to sell user information to marketing companies and that is exactly what facebook was guilty of. In 2018 it was revealed that Facebook gave Cambridge Analytica access to sensitive information of 87 million users [2]. It is in these companies best interest to know what you google searched, know what coffee shop you like to visit, and what products you buy online, because in 2019 you are the product. After huge backlash from the Facebook - Cambridge scandal, Jim Issaak and Mina J Hanna went on to explain in a peer reviewed article that “It is clear that national governance institutions demonstrably lack the ability to anticipate technology's future impact on the rights and duties of its citizens”[2]. 

                                    </p>
                                    <img src={ require('./genderswap2.jpg') } style={{maxWidth:"100%", marginTop: 15}} />
                                    <p style={{ fontFamily: 'Quattrocento Sans',fontSize: '19px', lineHeight: '28px',  }}>
                                    
                                        Being hacked is one thing how hackers use that data is another. Now more than ever it is important to protect your privacy with the rise of deep video and voice fakes. Technology is getting really good to the point where given enough pictures and audio clips of your voice one could create a completely fabricated video of you committing a crime. One example of this is the recent scam of August 2019 where an AI was used to mimic a CEO’s voice resulting in the transfer and theft of 243 thousand dollars [5]. People who use AI such as Alexa, Google Home, Siri, Bixby, and Cortana are at a huge risk of AI voice faking due to the constant recording of voice personal patterns. This is nothing new as the infamous robocalls have been known to actively record people saying phrases such as “yes”.

                                    </p>
                                    
                                    <p style={{ fontFamily: 'Quattrocento Sans',fontSize: '19px', lineHeight: '28px',  }}>
                                        What can you do? The first thing you should do is accept the possibility that your privacy may be violated. The second thing you should do is educate yourself and the people around you in an effort to soften the impact. Other precautions include using an antivirus software, covering your webcam, and making sure to use different and secure passwords. Another thing you should do is enable multi-factor authentication. In general there are five forms of Factor authentication [4]. the first being something you know like a password, the second being something you have like a yubikey or smartphone, the third being something you are like your fingerprint, the fourth being somewhere you are like your ip and mac address, and the fifth being something you do such as how you walk, talk, and move. The more forms of authentication you use to login to your devices the harder it is for hackers to impersonate and gain access to your online accounts. And Finally the last thing you should do is: don’t stress too much, life is short and in the grand scheme of things anxiety from information privacy is definitely not worth it!
                                    </p>

                                    <p style={{ fontFamily: 'Quattrocento Sans',fontSize: '19px', lineHeight: '28px',  }}>
                                        Written by Andrew Price
                                        <IconButton aria-label="twitter" color="primary" className={classes.icon} href={"https://twitter.com/TodaDyle"} target="_blank">
                                                        <FontAwesomeIcon icon={faTwitterSquare} />
                                        </IconButton>
                                    </p>
                                    <p style={{ fontFamily: 'Quattrocento Sans',fontSize: '19px', lineHeight: '28px',  }}>
                                        Sources:
                                    </p>
                                    <p style={{ fontFamily: 'Quattrocento Sans',fontSize: '13px', lineHeight: '20px',  }}>
                                        
                                            [1] Moore, Tyler. “On the Harms Arising from the Equifax Data Breach of 2017.” 
                                            International Journal of Critical Infrastructure Protection, vol. 19, 2017, pp. 47–48.<br/>
                                            [2] Isaak, Jim, and Mina J Hanna. “User Data Privacy: Facebook, Cambridge Analytica, and Privacy Protection.” <br/>
                                            [3] Armerding, Taylor. “The 18 Biggest Data Breaches of the 21st Century.” 
                                            CSO Online, CSO, 20 Dec. 2018, <a target={"_blank"} href={"https://www.csoonline.com/article/2130877/the-biggest-data-breaches-of-the-21st-century.html"}>Link</a><br/>
                                            [4] Dias, Renan. “The 5 Factors of Authentication.” 
                                            Medium, 9 Dec. 2017, <a target={"_blank"} href={"https://medium.com/@renansdias/the-5-factors-of-authentication-bcb79d354c13"}>Link</a><br/>
                                            [5] Stupp, Catherine. “Fraudsters Used AI to Mimic CEO's Voice in Unusual Cybercrime Case.” 
                                            The Wall Street Journal, Dow Jones & Company, 30 Aug. 2019 <a target={"_blank"} href={"https://www.wsj.com/articles/fraudsters-use-ai-to-mimic-ceos-voice-in-unusual-cybercrime-case-11567157402"}>Link</a><br/>

                                    </p>
                                </Grid>
                            </Grid>
            </div>
        )
    }
}

A0001.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(A0001);


